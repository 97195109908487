import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGeoForForm,
  fetchGuestsRangeForForm,
  fetchPriceForForm,
  fetchWeekendsForForm,
  setAmountOfGuestsFrom,
  setDateFrom,
  setNameSearch,
  setSuppliersFilters,
  setVenuesFilters,
} from '../../../../store/suppliersSlice';
import { formattedDate } from '../../../../components/customDatePicker/shared';
import { parseDate } from '@internationalized/date';

export const useFiltersLogic = (props) => {
  const { onSuccess } = props;
  const dispatch = useDispatch();
  let { formGet, formSend, venuesFilters, selectedEventTypeId } = useSelector(
    (state) => state.suppliers
  );
  const { watch, handleSubmit, setValue } = useFormContext();
  const currentCategoryId = watch('category.id');

  useEffect(() => {
    dispatch(fetchPriceForForm());
    dispatch(fetchWeekendsForForm());
    dispatch(fetchGuestsRangeForForm());
    dispatch(fetchGeoForForm());
  }, []);
  const [isSubmit, setIsSubmit] = useState(false);
  const isVenues = currentCategoryId && currentCategoryId === 1;

  const isDisabled = (isSubmit && !isVenues) || !selectedEventTypeId;
  const getDateByOneDate = (date,dateTo=null) => {
    if (!date)
      return { dateFormStart: null, dateFormEnd: null, dateFiltersFrom: null, dateFiltersTo: null };
    const dateForStep = new Date(date);
    const dateFromFormatted = formattedDate(dateForStep, 'YYYY-MM-DD');
    const dateToFormatted = formattedDate(
      dateTo ? dateTo :  dateForStep.setDate(dateForStep.getDate() + 7),
      'YYYY-MM-DD'
    );
    const dateStart = parseDate(dateFromFormatted);
    const dateEnd = parseDate(dateToFormatted);
    return {
      dateFormStart: dateStart,
      dateFormEnd: dateEnd,
      dateFiltersFrom: dateFromFormatted,
      dateFiltersTo: dateToFormatted,
    };
  };
  const initFromHomePage = () => {
    const { dateFormStart, dateFormEnd, dateFiltersFrom, dateFiltersTo } = getDateByOneDate(
      formSend.dateFrom,formSend.dateTo
    );
    setValue('date', dateFormStart);
    setValue('dateRange', { start: dateFormStart, end: dateFormEnd });
    formSend.amountOfGuestsFrom && setValue('amountGuests', +formSend.amountOfGuestsFrom);
    formSend.nameSearch && setValue('nameSearch', formSend.nameSearch);
    dispatch(
      setVenuesFilters({
        dateFrom: dateFiltersFrom,
        dateTo: dateFiltersTo,
        amountOfGuestsFrom: +formSend.amountOfGuestsFrom,
        nameSearch: formSend.nameSearch,
        amountOfGuestsTo: +formGet.guestsRange.max,
      })
    );
    dispatch(
      setSuppliersFilters({
        eventDate: dateFiltersFrom,
        amountOfGuests: +formSend.amountOfGuestsFrom,
        nameSearch: formSend.nameSearch,
      })
    );
    setIsSubmit(true);
    dispatch(setDateFrom(null));
    dispatch(setAmountOfGuestsFrom(0));
    dispatch(setNameSearch(""));
  };
  const initFormByFilters = () => {
    if (Object.keys(venuesFilters).length !== 0) {
      const {
        dateFrom,
        dateTo,
        amountOfGuestsFrom,
        nameSearch,
        weekendEventsId,
        geoLocationsId,
        priceRangesId,
      } = venuesFilters;
      if (dateFrom && dateTo) {
        setValue('date', parseDate(dateFrom));
        setValue('dateRange', { start: parseDate(dateFrom), end: parseDate(dateTo) });
      }
      amountOfGuestsFrom && setValue('amountGuests', amountOfGuestsFrom);
      nameSearch && setValue('nameSearch', nameSearch);
      weekendEventsId &&
        weekendEventsId.length !== 0 &&
        setValue('weekendEventsId', weekendEventsId[0]);
      geoLocationsId &&
        geoLocationsId.length !== 0 &&
        setValue('geoLocationsId', geoLocationsId);
      priceRangesId && setValue('priceRangesId', priceRangesId);
      setIsSubmit(true);
    }
  };
  useEffect(() => {
    if (formSend.dateFrom) {
      //init from home page
      initFromHomePage();
    } else {
      initFormByFilters();
    }
  }, []);
  const onEdit = () => {
    setIsSubmit(false);
  };
  const onSubmit = () => {
    handleSubmit((data) => {
      const { dateFiltersFrom, dateFiltersTo } = getDateByOneDate(
        data.date ? data.date : data.dateRange ? data.dateRange.start : null
      );
      const currentDateFrom = data.dateRange
        ? formattedDate(data.dateRange.start, 'YYYY-MM-DD')
        : dateFiltersFrom;
      const currentDateTo = data.dateRange
        ? formattedDate(data.dateRange.end, 'YYYY-MM-DD')
        : dateFiltersTo;
      if (dateFiltersFrom && dateFiltersTo) {
        setValue('date', parseDate(dateFiltersFrom));
        setValue('dateRange', { start: parseDate(currentDateFrom), end: parseDate(currentDateTo) });
      }

      const currentAmountOfGuests = !!data.amountGuests
        ? { amountOfGuestsFrom: +data.amountGuests, amountOfGuestsTo: formGet.guestsRange.max }
        : {};
      const currentNameSearch = !!data.nameSearch
        ? {nameSearch: data.nameSearch}
        : {};

      const filtersVenues = {
        priceRangesId: data.priceRangesId,
        dateFrom: currentDateFrom,
        dateTo: currentDateTo,
        weekendEventsId: data.weekendEventsId ? [data.weekendEventsId] : [],
        geoLocationsId: data.geoLocationsId ? data.geoLocationsId : [],
        ...currentAmountOfGuests,
        ...currentNameSearch,
      };
      dispatch(setVenuesFilters(filtersVenues));
      const filtersSuppliers = {
        eventDate: dateFiltersFrom,
        weekendEventsId: data.weekendEventsId ? data.weekendEventsId : 0,
        geoLocationsId: data?.geoLocationsId ? data.geoLocationsId : [],
        amountOfGuests: +data.amountGuests || 0,
        nameSearch: data.nameSearch || "",
      };
      dispatch(setSuppliersFilters(filtersSuppliers));
      onSuccess && onSuccess();
      setIsSubmit(true);
    })();
  };
  return { formGet, onSubmit, isDisabled, currentCategoryId, isSubmit, onEdit, isVenues };
};
