import watsApp from "../../../assets/images/for-pages/whatsapp.png"
import styles from "./whatsApp.module.scss";

const WhatsApp = () => {

    function openWhatsApp(){
        window.open('https://api.whatsapp.com/send/?phone=972525600999&text&type=phone_number&app_absent=0','_blank')
    }

    return (
        <>
        <div style={{cursor: 'pointer'}} onClick={() => openWhatsApp()} id="floating-WhatsApp" className={styles.whatsApp}>
            <div className={styles.watsAppIcon}>
                <img alt="whatsApp" src={watsApp} />
            </div>
            <div className={styles.watsAppText}>
                {/* <span>שאלה או רעיון?</span> */}
                {/* <span>דברו איתנו</span> */}
                <span>התייעצות עם מפיק ללא עלות</span>
            </div>
        </div>
        </>
    )
}
export default WhatsApp