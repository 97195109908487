import styles from '../../styleSuppliers.module.scss';
import { Button, Typography } from '@mui/material';
import CustomTextField from '../../../../components/customTextField/CustomTextField';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import CustomDateRangePicker from '../../../../components/customDatePicker/CustomDateRangePicker';
import CustomDatePicker from '../../../../components/customDatePicker/CustomDatePicker';
import { useFiltersLogic } from './useFiltersLogic';
import { IS_NUMBER_REGEX } from '../../../../shared/regex';
const Filters = () => {
  const { formGet, onSubmit, isDisabled, currentCategoryId, isSubmit, onEdit, isVenues } =
    useFiltersLogic({});

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
    }
  }
  
  return (
    <section id={`filters`} className={styles.filters_container}>
      <div onKeyDown={handleKeyDown} className={styles.more_box}>

        <div className={styles.filters_box}>
        {isVenues ? (
          <div className={styles.filters_one_input_range}>
              <CustomDateRangePicker
                disabled={isDisabled}
                placeholder="בחר את תאריך האירוע"
                name="dateRange"
              />
          </div>
            ) : (
          <div className={styles.filters_one_input}>
              <CustomDatePicker disabled={isDisabled} placeholder="בחר את תאריך האירוע" name="date" />
          </div>
            )}
          <div className={styles.filters_one_input}>
            <CustomTextField
              disabled={isDisabled}
              rules={{
                min: { value: formGet.guestsRange.min || 1, message: 'Min value 1' },
                max: { value: formGet.guestsRange.max || 1200, message: 'Max value 1200' },
                pattern: { value: IS_NUMBER_REGEX, message: 'ערך לא חוקי' },
              }}
              typeInput="number"
              name="amountGuests"
              placeholder="הזן כמות אורחים"
            />
          </div>

          {isVenues && (
            <div className={styles.filters_one_input}>
              <CustomSelect
                disabled={isDisabled}
                placeholder="טווח מחירים למנה"
                options={formGet.priceRanges}
                name="priceRangesId"
                multiple
              />
            </div>
          )}
          <div className={styles.filters_one_input}>
            <CustomSelect
              disabled={isDisabled}
              multiple
              placeholder="איזור בארץ"
              options={formGet.geoLocations}
              name="geoLocationsId"
            />
          </div>
          <div className={styles.filters_one_input}>
            <CustomSelect
              disabled={isDisabled}
              placeholder="אירועים בסופ״ש"
              options={formGet.weekendEvents}
              name="weekendEventsId"
            />
          </div>

          {isVenues ? (
            <Button
              disabled={isDisabled}
              onClick={onSubmit}
              sx={{ height: '42px', maxWidth: '110px' }}
              variant="contained"
            >
              הצג
            </Button>
          ) : !isSubmit ? (
            <Button
              disabled={isDisabled}
              onClick={onSubmit}
              sx={{ height: '42px', maxWidth: '110px' }}
              variant="contained"
            >
              חיפוש
            </Button>
          ) : (
            <Typography onClick={onEdit} className={styles.filters_edit_text}>
              עריכת פרטי האירוע
            </Typography>
          )}
        </div>
        <div className={styles.filters_one_input_full_row}>
            <CustomTextField
              // disabled={isDisabled}
              
              typeInput="string"
              name="nameSearch"
              placeholder="חיפוש ספק"
            />
          </div>
      </div>
    </section>
  );
};
export default Filters;
